import React, {useEffect} from 'react';
import {Row, Col} from 'antd';
import Layout from '~components/layout';
import Seo from '~components/seo';
import ConsumerAuth from '~containers/authenticate/consumer-auth';
import {firebaseAnalytics} from '~utils/firebase';
import CusBreadcrumbs from '~components/breadcrumbs';
import CreateEdit from '~containers/account/address/create-edit';

import '../../styles.scss';

const EditAddressPage = props => {
  const {
    params: {id},
  } = props;

  useEffect(() => {
    firebaseAnalytics.logEvent('EditAddress_page_visited');
  }, []);

  const title = 'Chỉnh sửa địa chỉ';

  return (
    <ConsumerAuth>
      <Layout>
        <Seo title={title} />
        <Row justify='center' className='category-path com-bg-gray'>
          <Col xs={24} md={22} lg={21}>
            <CusBreadcrumbs items={['Thông tin tài khoản', 'Lịch sử đơn hàng', title]} />
          </Col>
        </Row>
        <Row justify='center'>
          <Col xs={24} md={22} lg={21}>
            <div id='account-page'>
              <div className='account-content'>
                <CreateEdit id={id} />
              </div>
            </div>
          </Col>
        </Row>
      </Layout>
    </ConsumerAuth>
  );
};

export default EditAddressPage;
